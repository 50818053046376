<template>
  <el-dialog
    id="SignatoryDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="80%"
    top="5vh"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @keydown.enter.native="onFormEnter"
    destroy-on-close
  >
    <el-header class="header2" height="48"
      ><el-row type="flex" justify="start" align="center" style="padding-left:11px">
        <TSeparator />
        <TIButton icon="plus_green" @onClick="createNewSignatory" :title="$t('Створити підписанта')" />
        <TIButton icon="edit_yellow" @onClick="editSignatory" :disabled="!selectedRows" :title="$t('Редагувати')" />
        <TIButton icon="delete_red" @onClick="deleteSignatory" :disabled="!selectedRows" :title="$t('Видалити')" />
        <TSeparator />
      </el-row>
    </el-header>
    <el-container v-loading="loading">
      <el-header class="header3" height="48"
        ><el-row type="flex" justify="start">
          <div>
            <el-input
              :placeholder="$t('Пошук')"
              style="width:308px;"
              prefix-icon="el-icon-search"
              clearable
              v-model="filterString"
              ref="filter"
              @keydown.esc.native="resetFilter($event, this)"
            />
          </div>
          <div class="push">
            <el-button type="danger" @click="onReset" :disabled="!selectedSignatory">{{ $t("Зкинути підписанта") }}</el-button>
          </div>
        </el-row></el-header
      >
      <div ref="flex_container" class="flex_container">
        <ag-grid-vue
          ref="signatoryTable"
          id="signatoryTable"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="single"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :treeData="false"
          :rowData="signatory"
          :gridOptions="gridOptions"
          :getDataPath="getDataPath"
          @selection-changed="onGridSelectionChanged"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @gridReady="onGridReady"
          @sortChanged="onGridColumnsChange"
          @columnResized="onGridColumnsChange"
          @columnMoved="onGridColumnsChange"
          @displayedColumnsChanged="onGridColumnsChange"
          :modules="modules"
          :localeTextFunc="gridLocale"
          :enableBrowserTooltips="true"
          :sideBar="sideBar"
          :pivotMode="false"
          :frameworkComponents="frameworkComponents"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button" :disabled="!selectedRows || !selectedRows.length">{{
        $t("Застосувати")
      }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <EditSignatory bus-event="signatory.forms.edit" @commit="onEditSignatoryCommit" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";
import GridCellWithIcons from "@/components/GridCellWithIcons.js";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

export default {
  name: "Signatory",
  props: ["busEvent"],
  components: {
    AgGridVue,
    Icon,
    TIButton,
    TSeparator,
    GridCellWithIcons,
    EditSignatory: () => import("@/components/forms/signatory/EditSignatory"),
  },
  data() {
    var that = this;
    return {
      frameworkComponents: null,
      empty: [],
      loading: false,
      modules: AllModules,
      dialogShow: false,
      dialogHeight: 0,
      resizeObserver: null,
      title: $tt("Керування юр. особами"),
      target: null,
      model: {},
      filterString: "",
      getDataPath: (data) => {
        return data.path;
      },
      filterString: "",
      selectedRows: null,
      axiomaId: null,
      orgName: null,
      waitForAnswer: false,
      keyUpTimer: null,
      filterHasChanged: false,
      selectedSignatory: null,
      gridApi: null,
      gridColumnApi: null,
      noRowsToShowTemplate: `<div><img src="${require("../../assets/icons/no_data.svg")}"></div>`,
      gridOptions: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data._id;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      columns: [
        {
          headerName: $tt("Назва"),
          field: "name",
          width: "150px",
          flex: 1,
          cellRenderer: "GridCellWithIcons",
          cellIcons: [
            {
              icon: "edit",
              type: "fas el-icon-fa-",
              color: "green",
              iconClass: "icon-action",
              title: function(params) {
                return $tt("Редагувати");
              },
              onClick: (params) => {
                this.editSignatory(null, params);
              },
            },
            {
              icon: "trash",
              iconClass: "icon-action",
              type: "fas el-icon-fa-",
              color: "red",
              title: $tt("Видалити"),
              show: function(params) {
                return params.data.historyCount && params.data.historyCount > 0;
              },
              onClick: (params) => {
                this.deleteSignatory(null, params);
              },
            },
          ],
        },
        {
          headerName: $tt("Повна назва"),
          field: "fullName",
          width: "150px",
          flex: 1,
        },
        {
          headerName: $tt("Юридична адреса"),
          field: "address",
          width: "150px",
          flex: 1,
        },
        {
          headerName: $tt("Банк, назва"),
          field: "bankName",
          width: "130px",
        },
        {
          headerName: $tt("Банк, МФО"),
          field: "bankMFO",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("Банк, ЄДРПОУ"),
          field: "bankEDRPOU",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("IBAN"),
          field: "IBAN",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("ІПН"),
          field: "IPN",
          width: "130px",
          type: "rightAligned",
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
    };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.frameworkComponents = { GridCellWithIcons: GridCellWithIcons };
  },
  created() {
    var that = this;
    bus.$on(this.busEvent + ".show", (data) => {
      if (!that.dialogShow) {
        that.title = (data && data.title) || that.title;
        that.filterString = "";
        that.selectedSignatory = data ? data.selectedSignatory : null;
        that.axiomaId = data ? data.axiomaId : null;
        that.orgName = data ? data.orgName : null;
        that.loading = true;
        that.dialogShow = true;
      }
    });
    bus.$on(this.busEvent + ".hide", () => {
      that.dialogShow = false;
      that.resizeObserver = null;
    });
  },
  computed: {
    signatory() {
      return this.$store.getters["orgs/signatory"](this.filterString);
    },
  },
  watch: {
    dialogHeight(value) {
      var containerHeight = value - 48 - 72 - 48 - 36;
      this.$refs.flex_container.style.height = `${containerHeight}px`;
    },
  },
  methods: {
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      var state = this.$store.getters["main/userSettings"]("signatoryGridColumnState");
      if (state) this.gridColumnApi.setColumnState(state);
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
        });
        this.resizeObserver.observe(this.$el.children[0]);
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
      this.resizeObserver = null;
      this.dialogHeight = 0;
    },
    resetFilter(event) {
      if (this.filterString != "") {
        event && event.stopPropagation();
        this.$refs.filter.clear();
      }
    },
    initSelection() {},
    onOpen() {
      this.observeHeight();
    },
    onClose() {
      this.unobserveHeight();
    },
    onOpened() {
      var that = this;
      this.loading = true;
      this.$store
        .dispatch("orgs/getSignatory")
        .then(() => {
          that.initSelection();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCommit() {
      this.dialogShow = false;
      this.$emit("select-commit", { result: this.selectedRows, axiomaId: this.axiomaId });
    },
    onReset() {
      this.$confirm(
        $tt("Ви дійсно бажаєте зкинути підписанта [{name}] для [{corp}]?", { name: this.selectedSignatory.name, corp: this.orgName.name }),
        $tt("Увага"),
        {
          confirmButtonText: $tt("Так"),
          cancelButtonText: $tt("Ні"),
          type: "warning",
        }
      )
        .then(() => {
          this.dialogShow = false;
          this.$emit("select-commit", { result: null, axiomaId: this.axiomaId });
        })
        .catch(() => {});
    },
    initSelection() {
      if (this.gridOptions && this.gridOptions.api) {
        this.gridOptions.api.deselectAll();
        if (this.selectedSignatory) {
          var node = this.gridOptions.api.getRowNode(this.selectedSignatory);
          if (node) {
            node.setSelected(true);
            this.gridOptions.api.ensureNodeVisible(node, "middle");
          }
        }
      }
    },
    onGridSelectionChanged() {
      var rows = this.gridOptions.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedRows = rows;
      } else {
        this.selectedRows = null;
      }
    },
    onFormEnter() {},
    onGridColumnsChange() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { signatoryGridColumnState: state });
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
    createNewSignatory() {
      bus.$emit("signatory.forms.edit.show", {
        title: $tt("Створення нового підписанта"),
        operation: "create",
        initModel: {
          name: "",
          fullName: "",
          orgName: "",
          bankName: "",
          bankMFO: "",
          bankEDRPOU: "",
          IBAN: "",
          IPN: "",
        },
      });
    },
    editSignatory(event, params) {
      var item = null;
      if (params) {
        item = params.data;
      } else if (this.selectedRows && this.selectedRows.length) {
        var rows = this.gridOptions.api.getSelectedRows();
        if (rows && rows.length) item = rows[0];
      }
      if (item) {
        bus.$emit("signatory.forms.edit.show", {
          title: $tt("Редагування підписанта"),
          operation: "update",
          initModel: item,
        });
      }
    },
    onEditSignatoryCommit(data) {
      if (data.operation == "create") {
        var loading = this.$loading({ target: "#EditSignatoryDialog>div", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("orgs/createSignatory", data.model)
          .finally(() => {
            loading && loading.close();
          })
          .then((newData) => {
            bus.$emit("signatory.forms.edit.hide");
            this.$message.success($tt("Підписанта створено!"));
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка створення"), err.data.msg);
          });
      } else if (data.operation == "update") {
        var loading = this.$loading({ target: "#EditSignatoryDialog>div", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("orgs/updateSignatory", data.model)
          .finally(() => {
            loading && loading.close();
          })
          .then(() => {
            bus.$emit("signatory.forms.edit.hide");
            var rowNode = this.gridApi.getRowNode(data.model._id);
            rowNode.setData(data.model);
            this.gridApi.redrawRows({ rowNodes: [rowNode] });
            this.$message.success($tt("Підписанта оновлено!"));
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка оновлення"), err);
          });
      }
    },
    deleteSignatory(event, params) {
      var item = null;
      if (params) {
        item = params.data;
      } else if (this.selectedRows && this.selectedRows.length) {
        item = this.selectedRows[0];
      }
      if (item) {
        this.$confirm($tt("Ви дійсно бажаєте видалити підписанта [{name}]?", { name: item.name }), $tt("Увага"), {
          confirmButtonText: $tt("Так"),
          cancelButtonText: $tt("Ні"),
          type: "warning",
        })
          .then(() => {
            this.loading = true;
            this.$store
              .dispatch("orgs/deleteSignatory", { signatoryId: item._id })
              .finally(() => {})
              .then(() => {
                this.$message.success($tt("Підписанта видалено!"));
              })
              .catch((err) => {
                this.loading = false;
                globalErrorMessage($tt("Помилка"), err);
              });
          })
          .catch(() => {});
      } else {
        this.$message.error($tt("Будь ласка, виберіть один рядок!"));
      }
    },
  },
};
</script>

<style lang="scss" scope>
#SignatoryDialog .header2 {
  background: #fff;
  line-height: 32px;
  border-bottom: 1px solid #ccc !important;
  padding: 0 6px !important;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

#SignatoryDialog.my-dialog .el-dialog--center .el-dialog__body {
  padding: 0px 16px 0 16px !important;
}
</style>
